import './src/assets/global.css'
import { Helmet } from 'gatsby-plugin-react-i18next'

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Helmet>
        <link
          rel='preload'
          href='/fonts/raleway-latin-500-normal.woff2'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href='/fonts/raleway-latin-800-normal.woff2'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href='/fonts/trirong-latin-300-normal.woff2'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href='/fonts/martel-latin-400-normal.woff2'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <link
          rel='preload'
          href='/fonts/martel-latin-700-normal.woff2'
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
        />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <title>Bruno Fusieger</title>
      </Helmet>
      {element}
    </>
  )
}
